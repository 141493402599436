<template>
  <WsMain>
    <WsReadSection
      :id="_id"
      :modelName="modelName"
      :urlModelName="urlModelName"
      :label="label"
      :fields="fields"
      :leftFields="leftFields"
      :rightFields="rightFields"
      returnModelName="shop_product_pre_order"
      @input="modelData=$event"
    >
      <template #leftInfoForm>
        <WsRow class="ws-info-form">
          <WsCol
            v-for="(field,fieldIndex ) in leftFields"
            :class="[
            `sm-col-${field.sm?field.sm:12}`,
            `col-${field.sm?field.sm:12}`
          ]"
            :key="fieldIndex"
          >
            <WsInfo
              v-bind="fields[field]"
              :specSettings="$_specSetting(field)"
              :value="modelData[field]"
            />
          </WsCol>
        </WsRow>
      </template>
    </WsReadSection>
  </WsMain>
</template>

<script>
import model from "@/__vue2stone_cms/models/shop_product_pre_order";
export default {
  data: () => ({
    modelName: model.modelName,
    urlModelName: model.urlModelName,
    label: model.label,
    fields: model.fields,
    leftFields: [
      "no",
      "name",
      "subtitle",
      "shop_classes",
      "featured_classes",
      "is_active",
      "duration",
      "shop_product_spec_settings",
      "shop_product_specs",
      "cover_image",
      "images",
      "description",
    ],
    rightFields: [],
    modelData: {},
  }),
  methods: {
    $_specSetting(fieldKey) {
      if (fieldKey === "shop_product_specs") {
        return this.modelData.shop_product_spec_settings || null;
      }
    },
  },
  computed: {
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>